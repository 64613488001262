import { useEffect, useMemo, useState } from "react";
import {
  type Event,
  EventSourcePolyfill,
  type MessageEvent,
} from "event-source-polyfill";
import type { LLMData } from "@/pages/task/chat-task-page";
import * as config from "@/config";
import type { StreamEvent } from "@langchain/core/dist/tracers/log_stream";
import type { ChatHistory } from "allgood-api/src/routes/trpc/llm";
import { useAuth } from "@/hooks/use-auth";

const eventHandler = (event: StreamEvent, type: "agent" | "system") => {
  const kind = event["event"];
  if (kind === "on_chat_model_stream") {
    // event["data"]["chunk"].text is OpenAI format
    // event["data"]["chunk"].kwargs?.content is Anthropic format
    const content =
      event["data"]["chunk"].text ?? event["data"]["chunk"].kwargs?.content;
    if (type === "agent" && content) {
      return content;
    }
  } else if (kind === "on_chat_model_end") {
    const content = event["data"]["output"].kwargs?.content;
    if (type === "agent" && content) {
      if (Array.isArray(content)) {
        return content
          .filter((c) => c.type === "text")
          .map((c) => c.text)
          .join(" ");
      }
      return content;
    }
  } else if (kind === "on_chain_stream") {
    // Handle errors which are only visible on on_chain_stream.
    if (type === "agent" && event.data.chunk.ERROR) {
      return event.data.chunk.ERROR.error?.message;
    }
  } else if (kind === "on_tool_start") {
    if (type === "system") {
      event["data"].input =
        typeof event["data"].input === "string"
          ? JSON.parse(event["data"].input)
          : event["data"].input;
      const input = event["data"].input;
      if (input) {
        event["data"].input =
          typeof input === "string" ? JSON.parse(input) : input;
      }

      const msg = `Retrieving: ${event["name"]} with inputs: ${JSON.stringify(event["data"]["input"])}`;
      console.log(msg);

      // Currently we do not use on_tool_start to visualize.
      return true;
    }
  } else if (kind === "on_tool_end") {
    if (type === "system") {
      console.log("on_tool_end >> data ", event["data"]);
      try {
        event["data"].input =
          typeof event["data"].input.input === "string"
            ? JSON.parse(event["data"].input.input)
            : event["data"].input.input;

        event["data"].output =
          typeof event["data"].output === "string"
            ? JSON.parse(event["data"].output)
            : event["data"].output;

        const content = event["data"].output.kwargs?.content;
        if (content) {
          return typeof content === "string" ? JSON.parse(content) : content;
        }
      } catch (_e) {}

      const msg = `Found: ${event["name"]} ${
        event["data"].output.length
          ? `${event["data"].output.length} entities`
          : JSON.stringify(event["data"].output)
      }`;
      console.log("on_tool_end >> message ", msg);
      return true;
    }
  } else {
    // console.log(event);
  }

  return "";
};

export const useStream = ({
  initialChatHistory,
  taskId,
  deliverableId,
  threadId,
  context = {},
  onToolEnd,
  onSubmit,
  interactiveMode,
}: {
  initialChatHistory: ChatHistory[];
  taskId: string;
  deliverableId: string;
  threadId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: Record<string, any>;
  onToolEnd?: (event: StreamEvent) => void;
  onSubmit: (llmData: LLMData) => void;
  interactiveMode: boolean;
}) => {
  const auth = useAuth();

  const [chatHistory, setChatHistory] =
    useState<ChatHistory[]>(initialChatHistory);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [stream, setStream] = useState<EventSourcePolyfill>();

  useEffect(() => {
    setChatHistory(initialChatHistory);
  }, [initialChatHistory]);

  const startStream = async (message: string) => {
    setIsStreaming(true);
    onSubmit({
      taskId: taskId,
      threadId: threadId,
      deliverableId,
      chatHistory,
    });

    let buffer = "";
    let agentResponse: ChatHistory;
    const newChatHistory = [
      ...chatHistory,
      {
        type: "user",
        message: message,
        userId: auth.user?.userId,
        name: `${auth.user?.profile.firstName} ${auth.user?.profile.lastName}`,
      },
    ] as ChatHistory[];

    setChatHistory(newChatHistory);

    const encoded = encodeURI(
      `taskId=${taskId}&deliverableId=${deliverableId}&threadId=${threadId}&interactiveMode=${interactiveMode}&promptText=${message}&context=${Object.entries(
        context,
      )
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n")}`,
    );

    const eventSource = new EventSourcePolyfill(
      `${config.API_SERVER}/api/llm/agent-workflow?${encoded}`,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      },
    );

    eventSource.addEventListener("message", (event: MessageEvent) => {
      try {
        const chunk = JSON.parse(event.data);

        if (chunk["event"] === "close") {
          eventSource.close();
          onSubmit({
            taskId,
            threadId,
            deliverableId: deliverableId,
            chatHistory: [...newChatHistory],
          });
          setIsStreaming(false);
        }

        if (chunk["event"] === "on_chat_model_start") {
          // sometimes llm started duplicated and no response.
          // if (
          //   buffer.length === 0 &&
          //   agentResponse.systemHistory?.length === 0 &&
          //   newChatHistory.length > 1
          // ) {
          //   newChatHistory.length = newChatHistory.length - 1;
          // }

          // New LLM response starting. Create a new agent response.
          buffer = "";
          agentResponse = {
            type: "agent",
            name: chunk["metadata"]["agent"],
            agentId: chunk["metadata"]["agentId"],
            message: buffer,
            systemHistory: [],
          };
          newChatHistory.push(agentResponse);
        }

        buffer += eventHandler(chunk, "agent");

        if (chunk["event"] === "on_chat_model_end") {
          // overwrite buffer with the final message.
          // on_chat_model_stream contains text + tool_use json data.
          // this way, we can remove the tool_use json data from the message.
          buffer = eventHandler(chunk, "agent");
        }

        if (onToolEnd) {
          if (chunk["event"] === "on_tool_end") {
            onToolEnd(chunk);
          }
        }
        const systemMsg = eventHandler(chunk, "system");
        if (systemMsg) {
          agentResponse.systemHistory!.push({
            message: systemMsg,
            event: chunk,
          });
        }
        agentResponse.message = buffer;
        setChatHistory([...newChatHistory]);
      } catch (_e) {}
    });

    eventSource.addEventListener("error", (event: Event) => {
      console.error("Error", event);
      // @ts-ignore
      if (!event.error?.message.includes("No activity")) {
        eventSource.close();
        onSubmit({
          taskId,
          threadId,
          deliverableId,
          chatHistory: [...newChatHistory],
        });
        setIsStreaming(false);
      }
      // @ts-ignore
    });

    setStream(eventSource);
  };

  const stopStream = () => {
    if (stream) {
      stream.close();
    }
    setChatHistory([
      ...chatHistory,
      {
        userId: auth.user?.userId,
        type: "user",
        message: "User stopped the conversation.",
      },
    ]);
    setStream(undefined);
    setIsStreaming(false);
  };

  const chatHistoryGroupByAgent = useMemo(() => {
    return chatHistory.reduce((acc, curr) => {
      if (acc.length === 0) {
        return [curr];
      }
      const last = { ...acc[acc.length - 1] };
      if (last.agentId === curr.agentId) {
        if (curr.message) {
          last.message += `\n${curr.message}`;
        }
        last.systemHistory = [
          ...(last.systemHistory ?? []),
          ...(curr.systemHistory ?? []),
        ];
        acc[acc.length - 1] = last;
        return acc;
      }
      return [...acc, curr];
    }, [] as ChatHistory[]);
  }, [chatHistory]);

  const chatHistoryGroupByType = useMemo(() => {
    return chatHistoryGroupByAgent.reduce(
      (acc, curr) => {
        if (acc.length === 0) {
          return [{ type: curr.type, chatHistory: [curr] }];
        }
        const last = { ...acc[acc.length - 1] };
        if (last.type === curr.type) {
          last.chatHistory = [...last.chatHistory, curr];
          acc[acc.length - 1] = last;
          return acc;
        }
        return [...acc, { type: curr.type, chatHistory: [curr] }];
      },
      [] as Array<{
        type: ChatHistory["type"];
        chatHistory: Array<ChatHistory>;
      }>,
    );
  }, [chatHistoryGroupByAgent]);

  return {
    threadId,
    chatHistory: chatHistoryGroupByType,
    isStreaming,
    startStream,
    stopStream,
  };
};
